<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen  hide-overlay transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Prodotti caricati</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Chiudi</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Codice
                </th>
                <th class="text-left">
                  Descrizione
                </th>
                <th></th>
                <th class="text-right">
                  Qtà
                </th>
                <th>
                </th>                
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in righe" :key="index">
                <td>{{ item.codice }}</td>
                <td>{{ item.descrizione }}</td>
                <td class="text-right"><v-btn color="success" @click.stop="aumentaQta(item)">+</v-btn><v-btn color="info" class="ml-5 mt-1 mb-1" @click.stop="diminuisciQta(item)">-</v-btn></td>
                <td class="text-right">{{ item.quantita }}</td>
                <td><v-icon small @click.stop="cancellaItem(item)">mdi-delete-outline</v-icon></td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
      </v-card>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Confermi cancellazione elemento?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">SI</v-btn>
            <v-btn color="blue darken-1" text @click="closeDelete">NO</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>      
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: {
      righe: Array,
      show: { type: Boolean, default: false }
    },
    data () {
      return {
        dialog: false,
        dialogDelete: false,
        itemToDelete: null
      }
    },
    watch: {
      show(value) {
        if (value) {
          this.dialog = value
        }
      }
    },
    methods: {
      async cancellaItem(item) {
        this.itemToDelete = item
        this.dialogDelete = true
      },
      deleteItemConfirm() {
        const item = this.itemToDelete
        let idx = this.righe.findIndex(x => x.rigaCodice === item.rigaCodice)
        this.righe.splice(idx, 1)
        this.$emit('updateScontrino')
        this.dialogDelete = false
        this.itemToDelete = null
      },
      closeDelete() {
        this.dialogDelete = false
        this.itemToDelete = null
      },
      async aumentaQta(item) {
        let riga = this.righe.find(x => x.rigaCodice === item.rigaCodice)
        riga.quantita++
        this.$emit('updateScontrino')
      },
      async diminuisciQta(item) {
        let riga = this.righe.find(x => x.rigaCodice === item.rigaCodice)
        if (riga.quantita <= 1) {
          return 
        }
        riga.quantita--
        this.$emit('updateScontrino')
      }
    }
  }
</script>
