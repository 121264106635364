<template>
  <v-dialog v-model="dialogConferma" max-width="500" :persistent="true">
    <v-card>
      <v-card-title class="text-h5">{{title}}</v-card-title>
      <v-card-text v-html="message"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="confirm">{{confirmText}}</v-btn>
        <v-btn v-if="!onlyOk" color="blue darken-1" text @click="cancel">{{cancelText}}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: { type: String, default: 'Confermi operazione' },
    confirmText: { type: String, default: 'SI' },
    cancelText: { type: String, default: 'NO' },
    message: { type: String, default: '' },
    onlyOk: { type: Boolean, default: false }
  },
  data(vm) {
    return {
      dialogConferma: false
    }
  },
  watch: {
    show(value) {
      this.dialogConferma = value
    }
  },
  methods: {
    confirm() {
      this.dialogConferma = false
      this.$emit('dialog-confirm')
    },
    cancel() {
      this.dialogConferma = false
      this.$emit('dialog-cancel')
    }
  }
}
</script>
